
import { defineComponent } from 'vue';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import baseSubscription from '@/components/view/common/subscription';
import { isOffice } from '@/methods/role';

export default defineComponent({
    components: {
        propertyBreadCrumb,
        baseSubscription
    },
    setup() {
        const breadCrumbHeaders = [{ label: WordList.NavInHtmlMenuPurchase }];

        const userType = isOffice() ? 'propertyOffice' : 'propertyCommunity';

        return {
            breadCrumbHeaders,
            userType
        };
    }
});
